/* eslint-disable max-lines */
import React, { Suspense, lazy } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { NAVIGATION } from 'constants/navigation';
import './appTopNavStyles.scss';
import AppStore from '../appStore';
import { Hotspot, Icon, Input, TopNavigation } from '@amzn/awsui-components-react/polaris';
import Modal from 'components/modal/modal';
import AmazonLogo from 'svgs/logo.png';
import { ContactSupportTwoTone } from '@material-ui/icons';
import { TaskType } from 'pages/tasks/taskModels';
import NightlightIcon from '@mui/icons-material/Nightlight';
import { Cookie } from 'cookiesStore/cookieStore';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import { getFolderMetadata, getPreferences } from 'api/graphql/query';

const AddReactionOutlinedIcon = lazy(() => import('@mui/icons-material/AddReactionOutlined'));
const ManageSearchIcon = lazy(() => import('@mui/icons-material/ManageSearch'));
const Form = lazy(() => import('form/form'));

interface Props {
  appStore?: AppStore;
  isPlaceholder?: boolean;
}

@inject('appStore') @observer
export default class AppTopNav extends React.PureComponent<Props, unknown> {
  @observable loading = true;
  @observable searchQuery = '';
  @observable darkModeIcon = document.body.classList.contains('awsui-polaris-dark-mode') ? (
    <Suspense fallback={<></>}>
      <Brightness5Icon />
    </Suspense>
  ) : (
    <Suspense fallback={<></>}>
      <NightlightIcon />
    </Suspense>
  );

  renderCreateFolderModal(): any {
    const { appStore } = this.props;
    return (
      <Suspense fallback={<></>}>
        <Modal
          primaryButtonLabel="Create Folder"
          modalStore={appStore!.appTopNavStore.folderModalStore}
          size={'medium'}
          primaryButtonDisabled={!appStore!.appTopNavStore.folderModalStore.isFormValid}
          onPrimary={appStore!.appTopNavStore.handleCreateFolder}
          onCancel={appStore!.appTopNavStore.handleCreateFolderCancelClick}
        >
          <Suspense fallback={<></>}>
            <Form formStore={appStore!.appTopNavStore.folderModalStore} groupContainer={false} />
          </Suspense>
        </Modal>
      </Suspense>
    );
  }

  renderCreateDocumentModal(): any {
    const { appStore } = this.props;
    const { documentModalStore: { documentType, templateIdentifier } } = appStore!.appTopNavStore;
    const docType = appStore?.getDocumentTypeModel(documentType)?.label;
    return (
      <Suspense fallback={<></>}>
        <Modal
          primaryButtonLabel={templateIdentifier ? `Create ${docType} Template` : `Create ${docType}`}
          modalStore={appStore!.appTopNavStore.documentModalStore}
          size={'medium'}
          primaryButtonDisabled={!appStore!.appTopNavStore.documentModalStore.isFormValid}
          onPrimary={appStore!.appTopNavStore.handleCreateDocument}
          onCancel={appStore!.appTopNavStore.handleCreateDocumentCancelClick}
        >
          <Suspense fallback={<></>}>
            <Form formStore={appStore!.appTopNavStore.documentModalStore} groupContainer={false} />
          </Suspense>
        </Modal>
      </Suspense>
    );
  }


  renderPrferenceModal(): any {
    const { appStore } = this.props;

    return (
      <Suspense fallback={<></>}>
        <Modal
          modalStore={appStore!.appTopNavStore.preferenceModalStore}
          onPrimary={appStore!.appTopNavStore.handleSavePreference}
          primaryButtonLabel={'Submit'}
        >
          <Suspense fallback={<></>}>
            <Form formStore={appStore!.appTopNavStore.preferenceModalStore} groupContainer={false} />
          </Suspense>
        </Modal>
      </Suspense>
    );
  }

  @action.bound
  setSearchQuery(searchQuery: string) {
    this.searchQuery = searchQuery;
  }

  @action.bound
  handleKeyDown(e) {
    if (e.detail.key === 'Enter') {
      this.navigateToSearchPage(this.searchQuery);
    }
  }

  navigateToSearchPage(searchQuery = ''): void {
    const { appStore } = this.props;
    appStore!.appTopNavStore.onSearch(searchQuery);
  }

  @action.bound
  handleInput(e) {
    this.setSearchQuery(e.detail.value);
  }

  renderSearchBar(): any {
    const { appStore } = this.props;
    const isSearchPage = window.location.pathname?.includes('/search/');

    return (
      <>
        {appStore?.hideSearchBar || isSearchPage ?
          <></> :
          <section className="policy--topnav-searchbar">
            <Input
              name="search"
              value={this.searchQuery}
              onChange={this.handleInput}
              onKeyDown={this.handleKeyDown}
              placeholder="Policies, procedures, and standards search"
              type="search"
              className="policy--topnav-searchbar-input"
            />
            <span className="policy--advanced-search-button" title="Advanced Search" onClick={() => this.navigateToSearchPage()}>
              <Suspense fallback={<></>}>
                <ManageSearchIcon />
              </Suspense>
            </span>
          </section >
        }
      </>
    );
  }

  getMyOrgId() {
    const { appStore } = this.props;

    appStore?.api?.query(getPreferences, {})
      .then(action(({ data }) => {
        const preferences = JSON.parse(data.getPreferences);
        appStore!.appTopNavStore.organizationUrl = preferences?.organizationUrl;
      }));
  }
  handleOrgRedirect() {
    const { appStore } = this.props;

    if (appStore?.appTopNavStore.organizationUrl) {
      appStore?.api?.query(getFolderMetadata, { folderId: appStore?.appTopNavStore.organizationUrl })
        .then(action(folderData => {
          const folderMetadata = folderData.data.getFolderMetadata;
          const prefix = folderMetadata.folderAlias.length ? 'org' : 'folder';
          const folderSuffix = folderMetadata.folderAlias.length ? folderMetadata.folderAlias : folderMetadata.id;
          const url = `${window.location.origin}/${prefix}/${folderSuffix}`;
          window.location.href = url;
        }));
    } else {
      const url = `${window.location.origin}`;
      window.location.href = url;
    }
  }
  render(): any {
    const { appStore, isPlaceholder } = this.props;
    this.getMyOrgId();
    const myOrgRedirect: any[] = [];
    myOrgRedirect.push({
      type: 'button',
      ariaLabel: 'Org redirect',
      text: 'My Org',
      onClick: () => this.handleOrgRedirect(),
    });

    return (
      <>
        <TopNavigation
          id="policy--topnav"
          identity={{
            href: `${NAVIGATION.LANDING_PAGE.PATH}`,
            title: '',
            logo: {
              src: AmazonLogo, alt: 'Amazon Policy 2.0',
            },
          }}
          utilities={isPlaceholder ? [] : [
            {
              type: 'menu-dropdown',
              ariaLabel: 'Create New Item',
              text: '+ New',
              onItemClick: item => appStore?.appTopNavStore!.handleCreateClick(item),
              items: [
                {
                  text: 'Folder',
                  id: 'folder',
                },
                {
                  text: 'Document',
                  items: appStore!.documentTypes?.map(type => ({
                    text: type.label, id: `document-${type.id}`,
                  })) || [],
                },
                {
                  text: 'Template',
                  items: appStore!.templateTypes?.map(type => ({
                    text: type.label, id: `template-${type.id}`,
                  })) || [],
                },
              ],
            },
            {
              text: 'KNET Training',
              type: 'button',
              variant: 'link',
              href: 'https://knet.amazon.com/?/ui/lms-learner-playlist/PlaylistDetails?playlistId=b593e852-f4e8-410d-b6cd-2cbbad6ec8e4',
              external: true,
            },
            {
              type: 'menu-dropdown',
              title: 'How To Create and Manage...',
              ariaLabel: 'Getting Started',
              iconSvg: <div id="policy--getting-started-icon"><ContactSupportTwoTone /></div>,
              items: [
                {
                  text: 'Folders',
                  id: 'folder',
                  href: 'https://policy.a2z.com/docs/501045/publication',
                  external: false,
                  externalIconAriaLabel: 'How to create and manage a folder.',
                },
                {
                  text: 'Permissions',
                  id: 'folder',
                  href: 'https://policy.a2z.com/docs/501061/publication',
                  external: false,
                  externalIconAriaLabel: 'How to create and manage a permission.',
                },
                {
                  text: 'Policies',
                  id: 'document',
                  href: 'https://policy.a2z.com/docs/501049/publication',
                  external: false,
                  externalIconAriaLabel: 'How to create and manage policies.',
                },
                {
                  text: 'Approval Workflows',
                  id: 'workflow',
                  href: 'https://policy.a2z.com/docs/501221/publication',
                  external: false,
                  externalIconAriaLabel: 'How to create and manage approval workflows.',
                },
                {
                  text: 'Exceptions',
                  id: 'exceptions',
                  href: 'https://policy.a2z.com/docs/592198/publication',
                  external: false,
                  externalIconAriaLabel: 'How to create and manage exceptions.',
                },
                {
                  text: 'Scheduled Reviews',
                  id: 'scheduledReviews',
                  href: 'https://policy.a2z.com/docs/594305/publication',
                  external: false,
                  externalIconAriaLabel: 'How to create and manage scheduled reviews.',
                },
                {
                  text: 'Bulk Actions',
                  id: 'BulkActions',
                  href: 'https://policy.a2z.com/docs/607819/publication',
                  external: false,
                  externalIconAriaLabel: 'How to perform actions in bulk',
                },
                {
                  text: 'Reporting',
                  id: 'Reporting',
                  href: 'https://policy.a2z.com/docs/621211/publication',
                  external: false,
                  externalIconAriaLabel: 'How to access QuickSight dashboard and reporting data.',
                },
              ],
            },
            {
              type: 'button',
              variant: 'link',
              external: false,
              onClick: e => {
                e.stopPropagation();
                appStore?.showFeedback(!appStore.isFeedbackVisible, true);
              },
              iconSvg: (
                <div id="policy--feedback-icon" style={{ color: appStore?.isFeedbackVisible ? '#539fe5' : 'inherit' }}>
                  <Suspense fallback={<></>}>
                    <AddReactionOutlinedIcon />
                  </Suspense>
                </div>
              ),
              ariaLabel: 'Feedback',
            },
            {
              type: 'button',
              variant: 'link',
              external: false,
              onClick: action(e => {
                e.stopPropagation();
                document.body.classList.toggle('awsui-polaris-dark-mode');

                if (document.body.classList.contains('awsui-polaris-dark-mode')) {
                  appStore?.cookiesStore?.current?.setCookie(Cookie.DARK_MODE_ACTIVE);
                  document.body.classList.add('darkMode');
                  this.darkModeIcon = (
                    <Suspense fallback={<></>}>
                      <Brightness5Icon />
                    </Suspense>
                  );
                } else {
                  appStore?.cookiesStore?.current.removeCookie(Cookie.DARK_MODE_ACTIVE);
                  document.body.classList.remove('darkMode');
                  this.darkModeIcon = (
                    <Suspense fallback={<></>}>
                      <NightlightIcon />
                    </Suspense>
                  );
                }
              }),
              iconSvg: this.darkModeIcon,
              ariaLabel: 'Toggle dark mode',
            },
            ...myOrgRedirect,
            {
              type: 'menu-dropdown',
              text: '',
              iconName: 'user-profile',
              iconSvg: (
                <span title="Phone Tool Link" style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                  {this.props.appStore?.tutorialData?.tasks && (
                    <span className="hot-spot">
                      <Hotspot direction="left" hotspotId="menu" />
                    </span>
                  )}
                  <img id="user-icon" src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${this.props.appStore?.currentUserID}`} />
                  <Icon className="user-profile-caret" name="caret-down-filled" />
                </span>
              ),
              ariaLabel: 'Phone Tool Link',
              onItemClick: e => appStore?.appTopNavStore.handleMenuClick(e?.detail?.id),
              items: [
                {
                  id: 'myDashboard',
                  text: 'My Dashboard',
                  href: NAVIGATION.TASKBOARD_PAGE.LINK(TaskType.exceptions),
                  external: false,
                },
                {
                  id: 'bulkActions',
                  text: 'Bulk Actions',
                  href: NAVIGATION.BULK_ACTIONS.PATH,
                  external: false,
                },
                {
                  id: 'quickSightDashboard',
                  text: 'QuickSight Dashboard',
                  href: NAVIGATION.QUICKSIGHT_DASHBOARD.PATH,
                  external: true,
                },
                {
                  id: 'info',
                  text: 'Info',
                  external: true,
                  items: [
                    {
                      id: 'contactUs',
                      text: 'Contact Us',
                      href: 'https://w.amazon.com/bin/view/FinTech/AmazonPolicy/Contactus/officehours/',
                      external: true,
                    },
                    {
                      id: 'emailList',
                      text: 'Email List',
                      href: 'https://email-list.amazon.com/email-list/expand-list/policyinterest',
                      external: true,
                    },
                    {
                      id: 'updates',
                      text: 'Updates',
                      href: 'https://w.amazon.com/bin/view/FinTech/AmazonPolicy/',
                      external: true,
                    },
                    {
                      id: 'userGuide',
                      text: 'User Guide',
                      href: 'https://policy.a2z.com/docs/604045/publication',
                      external: true,
                    },
                  ],
                },
                {
                  id: 'submitRequest',
                  text: 'Request',
                  external: true,
                  items: [
                    {
                      id: 'bugFix',
                      text: 'Bug Fix',
                      href: 'https://issues.amazon.com/issues/create?template=7237de9e-1b31-4b64-8c96-62be3db8192a',
                      external: true,
                    },
                    {
                      id: 'change',
                      text: 'Change',
                      href: 'https://issues.amazon.com/issues/create?template=a870525e-f993-43a0-81ad-65ed2f0e75de',
                      external: true,
                    },
                    {
                      id: 'feature',
                      text: 'Feature',
                      href: 'https://issues.amazon.com/issues/create?template=75cc4d00-8e5d-4056-a6a5-eb96894df5a6',
                      external: true,
                    },
                    {
                      id: 'generalInquiry',
                      text: 'General Inquiry',
                      href: 'https://issues.amazon.com/issues/create?template=d1243a39-24e9-40ba-a8e9-92beb599d169',
                      external: true,
                    },
                    {
                      id: 'permission',
                      text: 'Permission',
                      href: 'https://issues.amazon.com/issues/create?template=33540d58-0506-402f-b388-72762e3532a5',
                      external: true,
                    },
                  ],
                },
              ],
            },
          ]}
          i18nStrings={{
            searchIconAriaLabel: 'Search',
            searchDismissIconAriaLabel: 'Close search',
            overflowMenuTriggerText: 'More',
            overflowMenuTitleText: '',
          }}
          search={isPlaceholder ? <></> : this.renderSearchBar()}
        />

        {/* Modals for the New dropdown button */}
        <span>{!isPlaceholder && appStore?.appTopNavStore?.folderModalStore && this.renderCreateFolderModal()}</span>
        <span>{!isPlaceholder && appStore?.appTopNavStore?.documentModalStore && this.renderCreateDocumentModal()}</span>
        <span>{ appStore?.appTopNavStore?.preferenceModalStore && appStore?.appTopNavStore?.showPreference && this.renderPrferenceModal()}</span>

      </>
    );
  }
}
